<template>
  <ErrorCatcher>
    <ReloadPrompt />
    <Toaster position="top-center" />

    <component :is="$route.meta.layoutComponent">
      <RouterView />
    </component>
  </ErrorCatcher>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch, nextTick } from 'vue'
import { Toaster } from 'vue-sonner'
import { useWakeLock, useOnline } from '@vueuse/core'
import ReloadPrompt from '@/components/ReloadPrompt.vue'
import ErrorCatcher from '@/components/ErrorCatcher.vue'
import { toaster } from '@/utils/toaster'
import { useI18n } from 'vue-i18n'
import useCookieLocale from '@/composables/useCookieLocale'
import { useUserDataStore } from '@/stores/user'

const { isSupported } = useWakeLock()
const { t, locale } = useI18n({ useScope: 'global' })
const { currentLang } = useCookieLocale()
const userStore = useUserDataStore()
const online = useOnline()

const toasterID = ref<number | string | null>(null)

watch(
  online,
  async (isOnline: boolean) => {
    nextTick(() => {
      console.log('watch is Online', isOnline)
      if (!isOnline) {
        toasterID.value = toaster.error(t('generalTips.offline'), {
          dismissible: false,
          closeButton: false,
          duration: Infinity
        })
      } else if (toasterID.value !== null) {
        toaster.dismiss(toasterID.value)
      }
    })
  },
  { immediate: true }
)

onMounted(async () => {
  if (userStore.userData?.user?.lang || currentLang.value) {
    locale.value = (userStore.userData?.user?.lang || currentLang.value) as string
  } else {
    currentLang.value = navigator.language
  }

  if (!isSupported.value) {
    toaster.warn(t('wakeLock.notSupported'))
    return
  }
})
</script>
